//User
export const API_USERS_SIGNUP = "/v1/users/signup";
export const API_USERS_SIGNIN = "/v1/users/signin";
export const API_USERS_LIST = "/v1/users";
export const API_USERS_PROFILE = "/v1/users/:id";
export const API_SWITCH_USER = "/v1/users/switch-user";
export const API_USER_DELETE = "/v1/users/delete";
export const API_USER_LOGOUT = "/v1/users/logout";



//Company
export const API_COMPANY_LIST = "/v1/companies";
export const API_COMPANY_CREATE = "/v1/companies";
export const API_COMPANY_PROFILE = "/v1/companies/:id";

//Call Centres
export const API_CALL_CENTRE_LIST = "/v1/call-centres";
export const API_CALL_CENTRE_CREATE = "/v1/call-centres";
export const API_CALL_CENTRE_PROFILE = "/v1/call-centres/:id";
export const API_CALL_CENTRE_USERS_LIST = "/v1/call-centres/:id/users";

//Trunks
export const API_TRUNKS_LIST = "/v1/trunks";
export const API_TRUNKS_CREATE = "/v1/trunks";
export const API_TRUNKS_EDIT = "/v1/trunks/:id";
export const API_TRUNKS_DELETE = "/v1/trunks/delete";

//Codecs
export const API_CODECS_LIST = "/v1/codecs";

//Country Codes
export const API_COUNTRY_CODES_LIST = "/v1/country-codes";

//Numbers
export const API_NUMBERS_UPLOAD = "v1/numbers/upload-numbers";
export const API_NUMBERS_CREATE = "v1/numbers/";
export const API_NUMBERS_LIST = "v1/numbers";
export const API_NUMBER_DELETE = "/v1/numbers/delete";
export const API_NUMBER_EDIT = "/v1/numbers/:id";
export const API_DID_NUMBERS = '/v1/numbers/did';
export const API_NUMBER_STATUS = '/v1/numbers/number-status';
export const API_BULK_ASSIGN_DID= '/v1/numbers/bulk/assign-did';
export const API_INDIVIDUAL_ASSIGN_DID ='/V1/numbers/individual/assign-did';
export const API_NUMBER_FILE_LIST = 'v1/number-filelist/';
export const API_NUMBER_FILE_LIST_DELETE = 'v1/number-filelist/delete'
export const API_NUMBER_STATUS_CHANGE = 'v1/numbers/status-change'
export const API_NUMBER_EDIT_BULK = "/v1/numbers/update-bulk"
export const API_DID_MAPPING = "/v1/numbers/did-mapping";
export const API_TO_ALLOCATE_NUMBERS = '/v1/numbers/to-allocate';
export const API_ALLOCATED_NUMBERS = '/v1/numbers/allocated';
export const API_REMOVE_NUMBERS ='/v1/numbers/remove';


//Ivr
export const API_IVR_CREATE = "/v1/call-centres/:cid/ivr";
export const API_IVR_LIST = "/v1/call-centres/:cid/ivr";
export const API_IVR_DELETE = "/v1/call-centres/delete";
export const API_IVR_EDIT = "/v1/call-centres/:cid/ivr/:id";

//Operators
export const API_OPERATORS_LIST = "/v1/operators";

//Timezones
export const API_TIMEZONES_LIST = "/v1/timezones";

//Data Center
export const API_DATA_CENTER_LIST = "/v1/data-center";
export const API_DATA_CENTER_CREATE = "/v1/data-center/create";
export const API_DATA_CENTER_EDIT = "/v1/data-center/:id";
export const API_DATA_CENTER_DELETE = "/v1/data-center/delete";

//state
export const API_STATES_LIST = "/v1/states";

//city
export const API_CITIES_LIST = "/v1/city";

//server
export const API_SERVER_MANAGEMENT_CREATE = "/v1/server-management/create";
export const API_SERVER_MANAGEMENT_LIST = "/v1/server-management";
export const API_SERVER_MANAGEMENT_DELETE = "/v1/server-management/delete";
export const API_SERVER_MANAGEMENT_EDIT = "/v1/server-management/:id";

//Module
export const API_MODULE_CREATE = "/v1/module";
export const API_MODULE_LIST = "/v1/module";
export const API_MODULE_DELETE = "/v1/module/delete";
export const API_MODULE_EDIT = "/v1/module/:id";
export const API_ACL_SETTING_CREATE = "/v1/acl-settings/create";
export const API_ACL_SETTING_LIST = "/v1/acl-settings";
export const API_ACL_SETTING_EDIT = "/v1/acl-settings/:id";
export const API_ACL_SETTING_DELETE = "/v1/acl-settings/delete";

//Journey Operation
export const API_JOURNEY_OPERATION_LIST = "v1/user-journey";

//Export Operation
export const API_LIST_EXPORT = "v1/export/:model";
